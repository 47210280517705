import { Form, Formik } from 'formik'
import React, { Suspense, useEffect, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { Navigate, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Accordion } from '@components/atoms/Accordion/Accordion'
import { Chip } from '@components/atoms/Chip/Chip'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { FeeInfoStrip } from '@components/atoms/FeeInfoStrip/FeeInfoStrip'
import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Banner } from '@components/atoms/tooltips/Banners/Banner'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { PlusMinusNumberInput } from '@components/molecules/forms/inputs/PlusMinusNumberInput'
import { BottomNav } from '@components/organisms/BottomNav/BottomNav'
import { ConfirmAddCardWithFee } from '@components/organisms/modals/ConfirmAddCardWithFee/ConfirmAddCardWithFee'
import { colours } from '@configs/colours'
import { cartSteps, claimCodePaths } from '@configs/urls'
import { Languages } from '@constants/language'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useCartContext } from '@hooks/useCart'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useMainKeyContext } from '@hooks/useMainKey'
import { useProductsContext } from '@hooks/useProducts'
import { useSettingsContext } from '@hooks/useSettings'
import { getValidationSchema } from '@schemas/pages/card/[hash]/catalogue/[productId]/schema'
import { api } from '@services/api'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { FeePaymentOptionEnum } from '@typeDeclarations/feePaymentOption'
import { HttpCodeNames, HttpCodes } from '@typeDeclarations/httpCodes'
import { determineIfDeducted } from '@utils/determineIfDeducted'
import { getAbsoluteHref } from '@utils/getAbsoluteHref'
import { getCurrencySymbol } from '@utils/getCurrencySymbol'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { getSuggestedPriceChoices } from '@utils/getPriceChoices'
import { mediaQueries } from '@utils/mediaQueries'
import { once } from '@utils/once'

const initialInfo = {
  howItWorks: true,
  terms: false,
  validity: false,
  about: false,
}

const Product: React.FC = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { claimCode = '' } = useParams()
  const { mainKey } = useMainKeyContext()
  const { productId = '' } = useParams()
  const { pathname } = useLocation()
  const { addCard, getCurrentMainChoiceCard, getCurrentCart, saveCart } = useCartContext()
  const { hashFlows } = useHashFlowContext()
  const { products, productsErrorCode, reloadProducts } = useProductsContext()
  const { reloadShopConfig } = useSettingsContext()

  const [ownAmountExpanded, setOwnAmountExpanded] = useState(false)
  const [customValueUsed, setCustomValueUsed] = useState(false)
  const [nothingToAdd, setNothingToAdd] = useState(false)
  const [errorBanner, setErrorBanner] = useState(false)
  const [checkoutBanner, setCheckoutBanner] = useState(false)
  const [confirmAddCardWithFeeModalOpen, setConfirmAddCardWithFeeModalOpen] = useState(false)
  const [balanceAfterThisCard, setBalanceAfterThisCard] = useState<number>()

  useEffect(() => {
    void reloadProducts()
    void reloadShopConfig()
  }, [])

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }, [pathname])

  const maxPurchaseQuantity = mainKey ? hashFlows[mainKey]?.maxPurchaseQuantity : null
  const maxPurchaseAmount = mainKey ? hashFlows[mainKey]?.maxPurchaseAmount : null
  const feePaymentOption = mainKey ? hashFlows[mainKey]?.feePaymentOption?.[0] : null
  const isFeePaymentOptionDeduct = feePaymentOption === FeePaymentOptionEnum.DeductFromCard
  const isFeePaymentOptionPay = feePaymentOption === FeePaymentOptionEnum.FeeViaPayment
  const totalCardsInCart = getCurrentCart()?.cards.reduce((a, c) => c.quantity + a, 0)
  const totalAmountInCart = getCurrentCart()?.cards.reduce((a, c) => c.quantity * c.amount + a, 0)
  const totalCardsOfThisType = getCurrentCart()?.cards.reduce((a, { id, quantity: q }) => {
    return id === productId ? a + q : a
  }, 0)

  const language = i18n.language as Languages
  const BANNER_TIMEOUT = 5000
  const mainChoiceCartBalance = getCurrentMainChoiceCard()?.debit_value
  const noBalance = !!(
    (typeof mainChoiceCartBalance === 'number' || typeof mainChoiceCartBalance === 'string') &&
    Number(mainChoiceCartBalance === 0)
  )

  const product = products?.products.find((product) => product.id.toString() === productId)
  const feePercentage = product?.service_fee_percent
  const feeFlat = product?.service_fee_flat
  const maxQuantityOfThisType = product?.max_purchase_qty
  const denominatedValues = !!product?.denominated_values
  const maxAmount = Number.isNaN(Number(product?.max_value))
    ? undefined
    : Number(product?.max_value)
  const minAmount = Number.isNaN(Number(product?.min_value))
    ? undefined
    : Number(product?.min_value)

  const hasAnyAboutInfo =
    !!product?.spending_text ||
    !!product?.conditions_text ||
    !!product?.expiration_text ||
    !!product?.product_url ||
    !!product?.description_short

  const [cardInfoState, cardInfoDispatch] = useReducer(
    (
      state: typeof initialInfo,
      action: {
        which: keyof typeof initialInfo
        type?: 'toggle' | 'open' | 'close'
        collapseOthers?: boolean
      },
    ): typeof initialInfo => {
      const selected = state[action.which]
      const newSelectedState = {
        [action.which]: action.type === 'open' ? true : action.type === 'close' ? false : !selected,
      }

      if (newSelectedState[action.which] && action.collapseOthers) {
        for (const key in state) state[key as keyof typeof state] = false
      }

      return {
        ...state,
        ...newSelectedState,
      }
    },
    initialInfo,
  )

  const handleGoBack = () =>
    navigate(generatePath(claimCodePaths.catalogue, { claimCode: mainKey }))

  const getBalanceAfterThisCard = async ({
    amount,
    quantity,
  }: {
    amount: number | ''
    quantity: number
  }): Promise<void> => {
    const deducted = determineIfDeducted(feePaymentOption, denominatedValues)

    const { base_value, fee } = await api.calculateFee({
      amount: quantity,
      claim_code: mainKey ?? '',
      pk: productId,
      value: amount || 0,
    })

    const currentCardsSumX = deducted
      ? Number(base_value) * quantity
      : (Number(base_value) + Number(fee)) * quantity

    const cart = getCurrentCart()
    const cardsSum = cart?.cards.reduce((a, c) => a + c.amount * c.quantity, 0) ?? 0
    const choiceCardsSum = cart?.choiceCardsToUse.reduce((a, c) => a + c.debit_value, 0) ?? 0
    const remaining = choiceCardsSum - cardsSum - currentCardsSumX

    setBalanceAfterThisCard(remaining)
  }

  const onQuantityValueChange = (amount: number | '') => (newValue: unknown) => {
    const correctNumber = !Number.isNaN(Number(newValue))

    if (correctNumber)
      void getBalanceAfterThisCard({
        quantity: Number(newValue),
        amount,
      })
  }

  const onAmountValueChange = (quantity: number) => (newValue: unknown) => {
    const correctNumber = !Number.isNaN(Number(newValue)) && !Number.isNaN(quantity)
    if (correctNumber)
      void getBalanceAfterThisCard({
        quantity: Number(quantity),
        amount: Number(newValue),
      })
  }

  const initialBalanceAfterThisCard = useRef(once(getBalanceAfterThisCard)).current

  if (
    productsErrorCode === HttpCodes.get(HttpCodeNames.Forbidden) ||
    productsErrorCode === HttpCodes.get(HttpCodeNames.Unauthroized) ||
    mainKey !== claimCode
  ) {
    return <Navigate to={generatePath(claimCodePaths.root, { claimCode })} />
  }

  if (productsErrorCode) {
    console.error('[Products error]: Products hook thrown error', productsErrorCode)
    return <Navigate to={generatePath(claimCodePaths.root, { claimCode })} />
  }

  type InitialValues = {
    quantity: number
    amount: '' | number
  }

  const initialValues: InitialValues = { quantity: 1, amount: '' }

  const hasFee =
    (typeof feePercentage === 'number' && feePercentage > 0) ||
    (typeof feeFlat === 'number' && feeFlat > 0)

  const addCardHandler = async (values: InitialValues) => {
    if (typeof values.amount !== 'number' || !product) return

    const cart = addCard({
      id: productId,
      amount: values.amount,
      currency: product.currency,
      denominatedValues,
      description: product.description_short,
      feeFlat,
      feePaymentOption,
      feePercentage,
      imageUrl: product.primary_image,
      maximumQuantity: product.max_purchase_qty,
      name: product.name,
      quantity: values.quantity,
    })
    saveCart(cart)

    setCheckoutBanner(true)
    setTimeout(() => setCheckoutBanner(false), BANNER_TIMEOUT)
  }

  return (
    <Root>
      <BannerHolder>
        <BannerSticker>
          {checkoutBanner && (
            <Banner
              onToastAction={() => navigate(generatePath(cartSteps.overview, { claimCode }))}
              title={t('addedToCart')}
              toastActionIcon={Icons.Cart}
              type="success"
            >
              {t('continueToCheckoutEncouragement')}
            </Banner>
          )}
          {nothingToAdd && (
            <Banner
              onClose={() => setNothingToAdd(false)}
              title={t('nothingToAdd')}
              toastActionIcon={Icons.Cart}
              type="generic"
            >
              {t('nothingToAddLong')}
            </Banner>
          )}
          {errorBanner && (
            <Banner
              onClose={() => setErrorBanner(false)}
              title={t('notPossible')}
              toastActionIcon={Icons.Cart}
              type="error"
            >
              {t('notPossibleDescription')}
            </Banner>
          )}
        </BannerSticker>
      </BannerHolder>
      <WidthBoundary>
        <TopHeaderH2 onClick={handleGoBack}>
          <Suspense>
            <IconComponents.arrowLeft />
            {t('back')}
          </Suspense>
        </TopHeaderH2>
      </WidthBoundary>
      <Content>
        <MainInfo>
          <CardImgWrapper>
            <CardImg src={product?.primary_image} />
            <StyledInfoStrip
              currency={product?.currency}
              feeFlat={feeFlat}
              feePercentage={feePercentage}
              isFeeDeducted={isFeePaymentOptionDeduct}
              isFeePaid={isFeePaymentOptionPay}
            />
          </CardImgWrapper>
          <CardTitle>{product?.name}</CardTitle>
          <CardText>
            <Markdown className="__markdown">{product?.description_short}</Markdown>
          </CardText>
          {hasAnyAboutInfo && (
            <Underlined
              onClick={() => {
                cardInfoDispatch({ which: 'about', type: 'open', collapseOthers: true })
                document.getElementById('info')?.scrollIntoView()
              }}
            >
              {t('readMore')}
            </Underlined>
          )}
        </MainInfo>
        <WhiteContainer>
          {product && (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={addCardHandler}
              validateOnChange
              validationSchema={getValidationSchema(
                language,
                t,
                product,
                maxPurchaseQuantity,
                totalCardsInCart,
                maxQuantityOfThisType,
                totalCardsOfThisType,
                maxPurchaseAmount,
                totalAmountInCart,
                feePaymentOption,
                feeFlat,
                feePercentage,
              )}
            >
              {({ setFieldValue, values, errors }) => {
                initialBalanceAfterThisCard(values)?.catch((e: unknown) => {
                  console.error('[Calculate fee] Error calculating initial fee in detail', e)
                })

                return (
                  <WithTranslateFormErrors>
                    <Form>
                      {confirmAddCardWithFeeModalOpen && (
                        <Suspense>
                          <ConfirmAddCardWithFee
                            currency={product.currency}
                            feeFlat={feeFlat}
                            feePercentage={feePercentage}
                            isFeeDeducted={isFeePaymentOptionDeduct}
                            isFeePaid={isFeePaymentOptionPay}
                            onClose={() => {
                              setConfirmAddCardWithFeeModalOpen(false)
                            }}
                            open={confirmAddCardWithFeeModalOpen}
                          />
                        </Suspense>
                      )}
                      <DarkWrapper>
                        <ValueHeaderH2>{t('chooseValue')}</ValueHeaderH2>
                        <ValueChoices>
                          {getSuggestedPriceChoices(
                            product,
                            maxPurchaseAmount,
                            feePaymentOption,
                            feeFlat,
                            feePercentage,
                          )
                            .sort((a, b) => a - b)
                            .map((choice) => (
                              <ChoiceChip
                                $customValueUsed={customValueUsed}
                                $initialState={values.amount === ''}
                                key={choice}
                                onClick={() => {
                                  void setFieldValue('amount', choice)
                                  setCustomValueUsed(false)
                                  setOwnAmountExpanded(true)
                                }}
                                selected={choice === values.amount}
                              >
                                {getLocalisedPrice(language, choice, product.currency)}
                              </ChoiceChip>
                            ))}
                        </ValueChoices>
                        <MobileAccordion
                          arrangement="center"
                          chevron={
                            <Suspense>
                              <IconComponents.chevron stroke={colours.blues[100]} />
                            </Suspense>
                          }
                          expanded={ownAmountExpanded}
                          onExpandedChange={() => setOwnAmountExpanded((p) => !p)}
                          titleText={<UnderlinedBlue>{t('chooseCustomAmount')}</UnderlinedBlue>}
                        >
                          <OwnValues>
                            <QuantityInput
                              description={t('forms.fields.quantity')}
                              min={1}
                              name="quantity"
                            />
                            <AmountInput
                              $step={5}
                              description={t('forms.fields.amount')}
                              disabled={denominatedValues}
                              lang={language}
                              max={maxAmount}
                              min={1}
                              name="amount"
                              onChange={() => setCustomValueUsed(true)}
                              onMinusClick={() => setCustomValueUsed(true)}
                              onPlusClick={() => setCustomValueUsed(true)}
                              placeholder={`${minAmount}`}
                              sign={getCurrencySymbol(product.currency)}
                            />
                          </OwnValues>
                          <ErrorWrapper>{errors.amount}</ErrorWrapper>
                          <ErrorWrapper>{errors.quantity}</ErrorWrapper>
                        </MobileAccordion>
                        <DesktopCustomChoice>
                          <OwnValues>
                            <QuantityInput
                              description={t('forms.fields.quantity')}
                              lang={language}
                              min={1}
                              name="quantity"
                              onValueChange={onQuantityValueChange(values.amount)}
                            />
                            <AmountInput
                              $step={5}
                              description={t('forms.fields.amount')}
                              disabled={denominatedValues}
                              lang={language}
                              max={maxAmount}
                              min={1}
                              name="amount"
                              onChange={() => setCustomValueUsed(true)}
                              onMinusClick={() => setCustomValueUsed(true)}
                              onPlusClick={() => setCustomValueUsed(true)}
                              onValueChange={onAmountValueChange(values.quantity)}
                              placeholder={`${minAmount}`}
                              sign={getCurrencySymbol(product.currency)}
                            />
                          </OwnValues>
                          <ErrorWrapper data-testid="catalogue-detail-errors-amount">
                            {errors.amount}
                          </ErrorWrapper>
                          <ErrorWrapper data-testid="catalogue-detail-errors-quantity">
                            {errors.quantity}
                          </ErrorWrapper>
                        </DesktopCustomChoice>
                      </DarkWrapper>
                      <BlueRectangle>
                        {typeof balanceAfterThisCard !== 'undefined' && balanceAfterThisCard <= 0
                          ? t('balanceExceeded', {
                              balance: getLocalisedPrice(language, 0, product.currency),
                            })
                          : t('balanceNotUsed', {
                              balance: getLocalisedPrice(
                                language,
                                balanceAfterThisCard,
                                product.currency,
                              ),
                            })}
                      </BlueRectangle>
                      <PrimaryButton
                        disabled={noBalance}
                        iconRight={noBalance ? Icons.Warning : Icons.Cart}
                        onClick={() => {
                          const nothingToAdd = values.amount === ''
                          const hasErrors = Object.keys(errors).length > 0

                          if (nothingToAdd) {
                            setNothingToAdd(true)
                            setTimeout(() => {
                              setNothingToAdd(false)
                            }, BANNER_TIMEOUT)
                            return
                          }

                          if (hasErrors && !ownAmountExpanded) {
                            setErrorBanner(true)
                            return
                          }

                          if (!hasErrors && hasFee) setConfirmAddCardWithFeeModalOpen(true)
                        }}
                        role={hasFee ? 'button' : 'submit'}
                        type={hasFee ? 'button' : 'submit'}
                      >
                        {noBalance ? t('mainChoiceCardEmpty') : t('forms.actions.addToCart')}
                      </PrimaryButton>
                    </Form>
                  </WithTranslateFormErrors>
                )
              }}
            </Formik>
          )}
        </WhiteContainer>
      </Content>
      {hasAnyAboutInfo && (
        <About id="info">
          <WidthBoundary>
            {product.spending_text && (
              <div>
                <Accordion
                  expanded={cardInfoState.howItWorks}
                  onExpandedChange={() => {
                    cardInfoDispatch({ which: 'howItWorks' })
                  }}
                  titleText={<AboutTitle>{t('howItWorks')}</AboutTitle>}
                >
                  <AboutText>
                    <Markdown className="__markdown">{product.spending_text}</Markdown>
                  </AboutText>
                </Accordion>
                <Hr />
              </div>
            )}
            {product.description_short && (
              <div>
                <Accordion
                  expanded={cardInfoState.about}
                  onExpandedChange={() => {
                    cardInfoDispatch({ which: 'about' })
                  }}
                  titleText={<AboutTitle>{t('about')}</AboutTitle>}
                >
                  <AboutText>
                    <Markdown className="__markdown">{product.description_short}</Markdown>
                  </AboutText>
                </Accordion>
                <Hr />
              </div>
            )}
            {product.conditions_text && (
              <div>
                <Accordion
                  expanded={cardInfoState.terms}
                  onExpandedChange={() => {
                    cardInfoDispatch({ which: 'terms' })
                  }}
                  titleText={<AboutTitle>{t('termsAndConditions')}</AboutTitle>}
                >
                  <AboutText>
                    <Markdown className="__markdown">{product.conditions_text}</Markdown>
                  </AboutText>
                </Accordion>
                <Hr />
              </div>
            )}
            {product.expiration_text && (
              <div>
                <Accordion
                  expanded={cardInfoState.validity}
                  onExpandedChange={() => {
                    cardInfoDispatch({ which: 'validity' })
                  }}
                  titleText={<AboutTitle>{t('validity')}</AboutTitle>}
                >
                  <AboutText>
                    <Markdown className="__markdown">{product.expiration_text}</Markdown>
                  </AboutText>
                </Accordion>
                <Hr />
              </div>
            )}
            {product.product_url && (
              <a href={getAbsoluteHref(product.product_url)} rel="noreferrer" target="_blank">
                <PrimaryButton iconRight={Icons.ExternalResource} style={{ marginTop: '24px' }}>
                  {product.product_url}
                </PrimaryButton>
              </a>
            )}
          </WidthBoundary>
        </About>
      )}
      <BottomNav />
    </Root>
  )
}

const DesktopCustomChoice = styled.div`
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

const MainInfo = styled.div`
  ${mediaQueries.from.breakpoint.desktop} {
    flex-basis: 50%;
    border-radius: 16px;
    background-color: white;
    padding: 8px;
    box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
    margin-bottom: 40px;
  }
`

const MobileAccordion = styled(Accordion)`
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`

const ErrorWrapper = styled(Fonts.BodyLarge)`
  color: ${colours.tomato[110]};
  margin-top: 8px;
`

const BannerHolder = styled.div`
  height: 100%;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
`

const BannerSticker = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  pointer-events: all;
  position: sticky;
  padding: 16px 0;
  top: 0;
  height: 0;
  box-sizing: border-box;
  ${mediaQueries.to.breakpoint.desktop} {
    width: 100%;
    padding: 16px 16px;
  }
  ${mediaQueries.from.breakpoint.desktop} {
    width: 592px;
  }
`

const BlueRectangle = styled(Fonts.BodyLarge)`
  padding: 16px;
  border-radius: 8px;
  background-color: ${colours.blues[10]};
  margin-bottom: 8px;
`

const Hr = styled.hr`
  border-top: 2px solid ${colours.prisma.white};
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin: 0;
`

const Root = styled.article`
  width: 100%;
  box-sizing: border-box;
  flex-grow: 10;
  position: relative;
  ${mediaQueries.from.breakpoint.desktop} {
    display: flex;
    flex-direction: column;
  }
`

const Content = styled.article`
  padding: 0 max(calc((100% / 2) - calc(1200px / 2)), 32px);
  display: flex;
  ${mediaQueries.from.breakpoint.desktop} {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  ${mediaQueries.to.breakpoint.desktop} {
    flex-direction: column;
    flex-grow: 10;
  }
`

const QuantityInput = styled(PlusMinusNumberInput)``
const AmountInput = styled(PlusMinusNumberInput)``

const WhiteContainer = styled.div`
  border-radius: 16px;
  background-color: white;
  padding: 8px;
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 40px;
  ${mediaQueries.from.breakpoint.desktop} {
    flex-basis: 50%;
  }
`

const CardImg = styled.img`
  max-width: 100%;
  border-radius: 12px;
  ${mediaQueries.from.breakpoint.desktop} {
    width: 100%;
  }
`

const CardTitle = styled(Fonts.TitleHeaderH1S)`
  margin-bottom: 8px;
  word-break: break-word;
`

const CardText = styled(Fonts.BodyRegular)`
  color: ${colours.mist[800]};
  margin-bottom: 8px;
  ${mediaQueries.to.breakpoint.desktop} {
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    white-space: pre-wrap;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`

const Underlined = styled(Fonts.ButtonTextLink)`
  color: ${colours.mist[800]};
  cursor: pointer;
  margin-bottom: 24px;
  text-decoration: underline;
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`

const UnderlinedBlue = styled(Fonts.ButtonTextLink)`
  text-decoration: underline;
  color: ${colours.blues[100]};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 40px;
`

const About = styled.div`
  background-color: ${colours.mist[200]};
  padding: calc(48px - 16px) 0 40px 0;
  ${mediaQueries.to.breakpoint.desktop} {
    box-sizing: border-box;
    min-height: calc(100vh - 72px);
  }
  ${mediaQueries.from.breakpoint.desktop} {
    flex-grow: 10;
  }
`

const AboutTitle = styled(Fonts.ButtonCTALabelLarge)`
  color: ${colours.brand.dark};
  margin-bottom: 16px;
  margin-top: 16px;
`

const OwnValues = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 5fr 8fr;
  gap: 8px;
  @media (max-width: 374px) {
    grid-template-columns: 1fr;
  }
`

const AboutText = styled(Fonts.BodyRegular)`
  color: ${colours.mist[800]};
  margin-bottom: 24px;
`

const HeaderH2 = styled(Fonts.TitleHeaderH2)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const TopHeaderH2 = styled(HeaderH2)`
  margin: 16px 0;
  cursor: pointer;
`

const ValueHeaderH2 = styled(HeaderH2)`
  margin: 0 0 8px 0;
  display: flex;
  gap: 8px;
`

const ChoiceChip = styled(Chip)<{
  selected?: boolean
  $customValueUsed?: boolean
  $initialState?: boolean
}>`
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  background-color: ${(p) => (p.selected ? colours.brand.dark : colours.mist[300])};
  ${(p) => p.$initialState && `background-color: ${colours.brand.dark}`};
  ${(p) => p.$customValueUsed && `background-color: ${colours.mist[800]}`}
`

const ValueChoices = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  flex-wrap: wrap;
`

const DarkWrapper = styled.div`
  background-color: ${colours.mist[200]};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
`

const CardImgWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
`

const StyledInfoStrip = styled(FeeInfoStrip)`
  position: absolute;
  left: 16px;
  bottom: 16px;
`

export default Product
