import { PropsWithChildren } from 'react'
import { Outlet, generatePath, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { BrandBar } from '@components/atoms/BrandBar/BrandBar'
import { Nav } from '@components/organisms/Nav/Nav'
import { colours } from '@configs/colours'

import yesty from '/images/brands/logos/yesty-new.png'

import { PATHS } from '@constants/paths'
import { useSettingsContext } from '@hooks/useSettings'

type Props = {
  noBranding?: boolean
  brandBar?: boolean
  logo?: boolean
}

const Layout: React.FC<PropsWithChildren<Props>> = ({
  children,
  brandBar = false,
  logo = false,
  noBranding,
}) => {
  const { imageLogo } = useSettingsContext()
  const navigate = useNavigate()
  const defaultBrandBarColor = colours.prisma.black

  return (
    <Root>
      <Header id="main-header">
        {brandBar && (
          <BrandBar $brandBarColor={noBranding ? defaultBrandBarColor : undefined} $height="6px" />
        )}
        <Nav
          hideActions
          Logo={
            logo ? (
              <LogoImg
                onClick={() => navigate(generatePath(PATHS.root))}
                src={imageLogo ?? yesty}
              />
            ) : null
          }
        />
      </Header>
      <LanguageSwitcherHandle id="modal-handle" />
      <BrandBarSpacer />
      <Outlet />
      {children}
    </Root>
  )
}

export default Layout

const BrandBarSpacer = styled.div`
  margin-bottom: 8px;
`

const LanguageSwitcherHandle = styled.div`
  height: 0;
  width: 100%;
  position: relative;
`

const Root = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
`

const Header = styled.header`
  width: 100%;
  height: 56px;
  position: sticky;
  top: 0;
  background-color: ${colours.prisma.white};
`

const LogoImg = styled.img`
  height: 16px;
  cursor: pointer;
`
