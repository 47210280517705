import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react'

import { useLocation, useParams } from 'react-router-dom'

import { ThemeBar } from '@components/molecules/context/branding'
import { colours } from '@configs/colours'
import { useCartContext, useHashFlowContext, usePrevious } from '@hooks/index'
import { useMainKeyContext } from '@hooks/useMainKey'
import { api } from '@services/api'
import {
  CheckCardFlow,
  LanguageSpecificBranding,
  Panel,
  ReceiveInfo,
  ShopBanner,
  ShopPersonalization,
} from '@services/api.types'

import yestyLogo from '/images/brands/logos/yesty-new.png'

import { HashFlowActions } from '@typeDeclarations/hashFlowActions'
import { parseConfigValues } from '@utils/parseConfigValues'
import { useTranslation } from 'react-i18next'

export type SettingsContext = {
  colorBar: Maybe<string>
  imageLogo: Maybe<string>
  languageSpecifics: Maybe<LanguageSpecificBranding[]>
  lastHash: Maybe<string>
  panel?: Maybe<Panel>
  referralCode: Maybe<string>
  reloadShopConfig: (manualCode?: string) => Promise<void>
  setAllBranding: (card: ReceiveInfo) => void
  setColorBar: (color: Maybe<string>) => void
  setDefaultBranding: () => void
  setImageLogo: (url: Maybe<string>) => void
  setLanguageSpecifics: (text: Maybe<LanguageSpecificBranding[]>) => void
  setLastHash: (hash: string) => void
  setPanel: (panel: Maybe<Panel>) => void
  setPersonalization: (card: ShopPersonalization) => void
  setReferralCode: (referralCode: Maybe<string>) => void
  setShopBannerType: (type: Maybe<ShopBanner>) => void
  setSlotImage: (url: Maybe<string>) => void
  setWelcomeImage: (url: Maybe<string>) => void
  shopBannerType: Maybe<string>
  slotImage: Maybe<string>
  welcomeImage: Maybe<string>
}

const defaultSettingsContext = {
  colorBar: colours.brand.orange,
  imageLogo: null,
  languageSpecifics: null,
  lastHash: null,
  panel: null,
  referralCode: null,
  shopBannerType: null,
  slotImage: null,
  welcomeImage: null,
  reloadShopConfig: () => Promise.resolve(),
  setAllBranding: () => {},
  setColorBar: () => {},
  setDefaultBranding: () => {},
  setImageLogo: () => {},
  setLanguageSpecifics: () => {},
  setLastHash: () => {},
  setPanel: () => {},
  setPersonalization: () => {},
  setReferralCode: () => {},
  setShopBannerType: () => {},
  setSlotImage: () => {},
  setWelcomeImage: () => {},
}

export const SettingsContext = createContext<SettingsContext>(defaultSettingsContext)

const prefix = '[Branding]'

const defaults = {
  product: { primary_image: undefined },
  shop_personalization: {
    color_bar: colours.brand.orange.substring(1),
    color_background: colours.prisma.black,
    image_logo: yestyLogo,
    shop_banner: ShopBanner.Clean,
    image_welcome: null,
    language_specifics: [
      {
        language: 'GB',
        shop_text: 'Shop',
        welcome_description: 'Lorem ipsum dolor sit amet',
        welcome_title: 'Welcome!',
      },
    ],
  },
}

export const SettingsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { shareKey, claimCode } = useParams()
  const { pathname } = useLocation()
  const { i18n } = useTranslation()
  const previousLanguage = usePrevious(i18n.language)

  const { clearCart } = useCartContext()
  const { mainKey } = useMainKeyContext()
  const { dispatchHashFlows } = useHashFlowContext()

  const [, setError] = useState()
  const [lastHash, setLastHash] = useState<string | null>(null)
  const [colorBar, setColorBar] = useState<Maybe<string>>(defaults.shop_personalization.color_bar)
  const [imageLogo, setImageLogo] = useState<Maybe<string>>(
    defaults.shop_personalization.image_logo,
  )
  const [shopBannerType, setShopBannerType] = useState<Maybe<ShopBanner>>(
    defaults.shop_personalization.shop_banner,
  )
  const [languageSpecifics, setLanguageSpecifics] = useState<
    Maybe<Array<LanguageSpecificBranding>>
  >(defaults.shop_personalization.language_specifics)
  const [slotImage, setSlotImage] = useState<Maybe<string>>(defaults.product.primary_image)
  const [welcomeImage, setWelcomeImage] = useState<Maybe<string>>(
    defaults.shop_personalization.image_welcome,
  )
  const [referralCode, setReferralCode] = useState<string | null>()
  const [panel, setPanel] = useState<Panel | null>()

  const language = i18n.language

  const setAllBranding = useCallback((card: Partial<ReceiveInfo>) => {
    setColorBar(card.shop_personalization?.color_bar)
    setImageLogo(card.shop_personalization?.image_logo)
    setShopBannerType(card.shop_personalization?.shop_banner)
    setLanguageSpecifics(card.shop_personalization?.language_specifics)
    setWelcomeImage(card.shop_personalization?.image_welcome)
  }, [])

  const setPersonalization = useCallback((personalization?: Partial<ShopPersonalization>) => {
    if (!personalization) return

    setColorBar(personalization?.color_bar)
    setImageLogo(personalization?.image_logo)
    setShopBannerType(personalization?.shop_banner)
    setLanguageSpecifics(personalization?.language_specifics)
    setWelcomeImage(personalization?.image_welcome)
  }, [])

  const setDefaultBranding = useCallback(() => {
    setAllBranding(defaults)
  }, [setAllBranding])

  const reloadShopConfig = useCallback(
    async (manualCode?: string) => {
      let code

      if (manualCode) code = manualCode
      if (claimCode && claimCode === mainKey) code = claimCode

      if (!code) return

      try {
        const response = await api.cardDetail(code)
        const { maxPurchaseQuantity, maxPurchaseAmount } = parseConfigValues(response.config)

        if (response.balance === 0) clearCart()

        setReferralCode(response.referral_code)
        setPanel(response.panel)
        setPersonalization(response?.shop_personalization)

        dispatchHashFlows({
          type: HashFlowActions.UpdateHashFlow,
          payload: { hash: code, maxPurchaseQuantity, maxPurchaseAmount },
        })
      } catch (e) {
        if (e instanceof TypeError) {
          setError(() => {
            throw e
          })
        }
        console.warn('[Settings] Something went wrong reloading shop config', e)
      }
    },
    [claimCode, mainKey, dispatchHashFlows, clearCart],
  )

  const isReceiveInfoType = (o: unknown): o is ReceiveInfo =>
    o !== null && typeof o === 'object' && 'product' in o

  useEffect(() => {
    const languageChanged = typeof previousLanguage !== 'undefined' && previousLanguage !== language

    if ((claimCode && claimCode === lastHash) || (shareKey && shareKey === lastHash)) {
      if (!languageChanged) return
    }

    const brandingSetter =
      (hash: string, defaults: boolean) => (r: ReceiveInfo | CheckCardFlow) => {
        if (!r.shop_personalization || defaults) {
          setDefaultBranding()
        } else {
          setAllBranding(r)
        }

        if (isReceiveInfoType(r) && r.product?.primary_image) {
          setSlotImage(r.product.primary_image)
        }

        setLastHash(hash)
      }

    if (shareKey) {
      api
        .receiveInfo(shareKey)
        .then(brandingSetter(shareKey, false))
        .catch((e: unknown) => {
          if (e instanceof TypeError) {
            setError(() => {
              throw e
            })
          }
          console.info(`${prefix} Setting default branding`)
          brandingSetter(shareKey, true)
        })

      return
    }

    const shortCode = claimCode
    if (shortCode) {
      api
        .cardDetail(shortCode)
        .then((response) => {
          const { maxPurchaseQuantity, maxPurchaseAmount } = parseConfigValues(response.config)

          dispatchHashFlows({
            type: HashFlowActions.UpdateHashFlow,
            payload: { hash: shortCode, maxPurchaseQuantity, maxPurchaseAmount },
          })

          setReferralCode(response.referral_code)
          setPanel(response.panel)

          brandingSetter(shortCode, false)(response)
        })
        .catch((e: unknown) => {
          if (e instanceof TypeError) {
            setError(() => {
              throw e
            })
          }
          api
            .checkCardFlow(shortCode)
            .then(brandingSetter(shortCode, false))
            .catch((e: unknown) => {
              if (e instanceof TypeError) {
                setError(() => {
                  throw e
                })
              }
              console.info(`${prefix} Setting default branding`)
              brandingSetter(shortCode, true)
            })
        })

      return
    }
  }, [
    claimCode,
    lastHash,
    mainKey,
    setDefaultBranding,
    shareKey,
    pathname,
    dispatchHashFlows,
    setAllBranding,
    language,
    previousLanguage,
  ])

  return (
    <SettingsContext.Provider
      value={{
        colorBar,
        imageLogo,
        languageSpecifics,
        lastHash,
        panel,
        referralCode,
        shopBannerType,
        slotImage,
        welcomeImage,
        reloadShopConfig,
        setAllBranding,
        setColorBar,
        setDefaultBranding,
        setImageLogo,
        setLanguageSpecifics,
        setLastHash,
        setPanel,
        setPersonalization,
        setReferralCode,
        setShopBannerType,
        setSlotImage,
        setWelcomeImage,
      }}
    >
      <ThemeBar color={colorBar} />
      {children}
    </SettingsContext.Provider>
  )
}
