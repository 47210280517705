import { flags, supportedLanguages } from '@i18n'
import { SettingTypeOf, SettingValue, useFeatureFlag } from 'configcat-react'
import { PropsWithChildren, Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'

import {
  Languages,
  TestEnvLanguage,
  languageCodeToName,
  manualLanguageSetKey,
} from '@constants/language'
import { zIndexes } from '@constants/zIndexes'
import { datadogRum } from '@datadog/browser-rum'
import { useOutsideClick } from '@hooks/useOutsideClick'
import { DivProps } from '@typeDeclarations/elements/div'
import { breakpoints, mediaQueries } from '@utils/mediaQueries'

interface Language {
  loading: boolean
  value: SettingTypeOf<SettingValue>
  key: string
}

interface LanguageFeatureFlags {
  [key: string]: boolean
}

export const LanguageSwitcher: React.FC<PropsWithChildren<DivProps>> = ({ ...props }) => {
  const { i18n, t } = useTranslation()
  const [open, setOpen] = useState(false)

  const languages: Language[] = [
    { key: 'de-enabled', ...useFeatureFlag('languages_de', false) },
    { key: 'en-GB-enabled', ...useFeatureFlag('languages_en-GB', false) },
    { key: 'en-US-enabled', ...useFeatureFlag('languages_en-US', false) },
    { key: 'da-enabled', ...useFeatureFlag('languages_da', false) },
    { key: 'no-enabled', ...useFeatureFlag('languages_no', false) },
    { key: 'et-enabled', ...useFeatureFlag('languages_et', false) },
    { key: 'es-enabled', ...useFeatureFlag('languages_es', false) },
    { key: 'fi-enabled', ...useFeatureFlag('languages_fi', false) },
    { key: 'fr-enabled', ...useFeatureFlag('languages_fr', false) },
    { key: 'it-enabled', ...useFeatureFlag('languages_it', false) },
    { key: 'lt-enabled', ...useFeatureFlag('languages_lt', false) },
    { key: 'lv-enabled', ...useFeatureFlag('languages_lv', false) },
    { key: 'nl-enabled', ...useFeatureFlag('languages_nl', false) },
    { key: 'pl-enabled', ...useFeatureFlag('languages_pl', false) },
    { key: 'sv-enabled', ...useFeatureFlag('languages_sv', false) },
    { key: 'cs-enabled', ...useFeatureFlag('languages_cs', false) },
    { key: 'hu-enabled', ...useFeatureFlag('languages_hu', false) },
    { key: 'pt-enabled', ...useFeatureFlag('languages_pt', false) },
    { key: 'ro-enabled', ...useFeatureFlag('languages_ro', false) },
    { key: 'el-enabled', ...useFeatureFlag('languages_el', false) },
  ]

  const languageFeatureFlags: LanguageFeatureFlags = useMemo(() => {
    return languages.reduce((acc, { key, value }) => {
      ;(acc as LanguageFeatureFlags)[key] = value as boolean
      return acc
    }, {})
  }, [languages])

  useEffect(() => {
    const allLanguageFlagsLoaded = languages.every(({ loading }) => !loading)

    if (allLanguageFlagsLoaded) {
      Object.entries(languageFeatureFlags).forEach(([key, value]) => {
        datadogRum.addFeatureFlagEvaluation(key, value)
      })
    }
  }, [languages, languageFeatureFlags])

  const desktopWrapperRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)

  const language = i18n.language as Languages | TestEnvLanguage
  const modalHandle = document.getElementById('modal-handle')
  const CurrentFlag = language === 'cimode' ? '🏁' : flags[language]

  function languageChangeHandler(lang: (typeof supportedLanguages)[number]) {
    localStorage.setItem(manualLanguageSetKey, 'true')
    i18n.changeLanguage(lang).then(() => setOpen(false))
  }

  useOutsideClick(desktopWrapperRef, (e) => {
    if (
      breakpoints.desktop <= window.innerWidth &&
      !triggerRef.current?.contains(e?.target as HTMLElement)
    ) {
      setOpen(false)
    }
  })

  return (
    <LanguageWrapper {...props}>
      <Trigger
        $open={open}
        onClick={() => {
          setOpen((v) => !v)
        }}
        ref={triggerRef}
      >
        <Info>
          {CurrentFlag}
          <DesktopText>
            {language !== 'cimode' ? languageCodeToName[language] : 'cimode'}
          </DesktopText>
        </Info>
        <IconWrapper $open={open}>
          <Suspense>
            <IconComponents.chevron fill={colours.mist[400]} stroke={colours.mist[400]} />
          </Suspense>
        </IconWrapper>
      </Trigger>
      {open && (
        <DesktopMenu>
          <DesktopRoot>
            <Triangle />
            <Wrapper id="language-select-wrapper-desktop" ref={desktopWrapperRef}>
              <ContentTitle>{t('selectLanguage')}</ContentTitle>
              <Content>
                {supportedLanguages.map((lang) => {
                  const languageEnabled: boolean = languageFeatureFlags[`${lang}-enabled`]

                  return (
                    languageEnabled && (
                      <Option
                        $selected={lang === language}
                        key={`${lang}-desktop`}
                        onClick={() => languageChangeHandler(lang)}
                      >
                        {flags[lang]}
                        <div>{languageCodeToName[lang]}</div>
                        {lang === language && (
                          <Suspense>
                            <IconComponents.checkmark stroke={colours.brand.green} />
                          </Suspense>
                        )}
                      </Option>
                    )
                  )
                })}
              </Content>
            </Wrapper>
          </DesktopRoot>
        </DesktopMenu>
      )}
      {open &&
        modalHandle &&
        createPortal(
          <MobileMenu
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setOpen(false)
            }}
          >
            <Wrapper
              id="language-select-wrapper-mobile"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <ContentTitle>{t('selectLanguage')}</ContentTitle>
              <Content>
                {supportedLanguages.map((lang) => {
                  const languageEnabled: boolean = languageFeatureFlags[`${lang}-enabled`]
                  return (
                    languageEnabled && (
                      <Option
                        $selected={lang === language}
                        key={`${lang}-mobile`}
                        onClick={() => languageChangeHandler(lang)}
                      >
                        {flags[lang]}
                        <div>{languageCodeToName[lang]}</div>
                        {lang === language && (
                          <Suspense>
                            <IconComponents.checkmark stroke={colours.brand.green} />
                          </Suspense>
                        )}
                      </Option>
                    )
                  )
                })}
              </Content>
            </Wrapper>
          </MobileMenu>,
          modalHandle,
        )}
    </LanguageWrapper>
  )
}

const Trigger = styled.div<{ $open?: boolean }>`
  align-items: center;
  background: ${(p) => (p.$open ? colours.mist[100] : 'unset')};
  border-radius: 40px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 8px 16px;

  ${mediaQueries.from.breakpoint.desktop} {
    min-width: 200px;
  }
`

const DesktopMenu = styled.div`
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

const MobileMenu = styled.div`
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }

  align-items: flex-start;
  background-color: ${colours.mist[400]};
  display: flex;
  height: 100vh;
  justify-content: center;
  padding-top: 5vh;
  position: relative;
  z-index: ${zIndexes.modal};
  width: 100%;
`

const Option = styled(Fonts.ButtonCTALabelLarge)<{ $selected?: boolean }>`
  height: 40px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 200px;
  border-radius: 36px;
  background: ${(p) => (p.$selected ? colours.mist[100] : 'unset')};
  cursor: pointer;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Content = styled.div`
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${colours.prisma.white};
  padding: 16px;
  max-height: calc(80vh);
  overflow-y: auto;
`

const ContentTitle = styled(Fonts.ButtonCTALabelLarge)`
  text-transform: uppercase;
  white-space: nowrap;
  margin-bottom: 16px;
`

const DesktopText = styled(Fonts.ButtonCTALabelLarge)`
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

const DesktopRoot = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% - 8px + 4px));
  right: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Wrapper = styled.div`
  align-items: center;
  background-color: ${colours.mist[100]};
  border-radius: 16px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100px;
  min-width: 100px;
  width: fit-content;
  padding: 16px;
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border: 8px solid rgba(0, 0, 0, 0);
  transform: scaleX(1.2);
  border-bottom-color: ${colours.mist[100]};
  margin-right: 72px;
`

const IconWrapper = styled.div<{ $open: boolean }>`
  line-height: 0;
  transform: ${(p) => (p.$open ? `rotate(0)` : `rotate(180deg)`)};
  transition: 100ms linear transform;
`

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const LanguageWrapper = styled.section`
  position: relative;
`
