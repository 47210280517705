import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { envs } from '@configs/envs.ts'

import de from './locales/de/translation.json'
import da from './locales/da/translation.json'
import et from './locales/et/translation.json'
import enGB from './locales/en-GB/translation.json'
import enUS from './locales/en-US/translation.json'
import es from './locales/es/translation.json'
import fi from './locales/fi/translation.json'
import fr from './locales/fi/translation.json'
import it from './locales/it/translation.json'
import lt from './locales/lt/translation.json'
import lv from './locales/lv/translation.json'
import nl from './locales/nl/translation.json'
import no from './locales/no/translation.json'
import pl from './locales/pl/translation.json'
import sv from './locales/sv/translation.json'
import cs from './locales/cs/translation.json'
import hu from './locales/hu/translation.json'
import pt from './locales/pt/translation.json'
import ro from './locales/ro/translation.json'
import el from './locales/el/translation.json'

const resources = {
  de: { translation: de },
  da: { translation: da },
  et: { translation: et },
  'en-GB': { translation: enGB },
  'en-US': { translation: enUS },
  es: { translation: es },
  fi: { translation: fi },
  fr: { translation: fr },
  it: { translation: it },
  lt: { translation: lt },
  lv: { translation: lv },
  nl: { translation: nl },
  no: { translation: no },
  pl: { translation: pl },
  sv: { translation: sv },
  cs: { translation: cs },
  hu: { translation: hu },
  pt: { translation: pt },
  ro: { translation: ro },
  el: { translation: el },
}

export const flags = {
  de: '🇩🇪',
  da: '🇩🇰',
  et: '🇪🇪',
  'en-GB': '🇬🇧',
  'en-US': '🇺🇸',
  es: '🇪🇸',
  fi: '🇫🇮',
  fr: '🇫🇷',
  it: '🇮🇹',
  lt: '🇱🇹',
  lv: '🇱🇻',
  nl: '🇳🇱',
  no: '🇳🇴',
  pl: '🇵🇱',
  sv: '🇸🇪',
  cs: '🇨🇿',
  hu: '🇭🇺',
  pt: '🇵🇹',
  ro: '🇷🇴',
  el: '🇬🇷',
}

export const supportedLanguages = Object.keys(resources) as (keyof typeof resources)[]
const translationsAppId = envs.VITE_TRANSLATIONS_APP_ID
const translationsEndpoint = envs.VITE_TRANSLATIONS_ENDPOINT
const translationsPrefix = '[Translations]'

const initOptions = {
  debug: false,
  detection: { order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'] },
  fallbackLng: 'en-GB',
  interpolation: { escapeValue: false },
  returnEmptyString: false,
  supportedLngs: Object.keys(resources),
  backend: {
    crossDomain: true,
    loadPath: (language: string, _namespace: string | string[]): string => language,
    request: async (
      _options: Record<string, unknown>,
      url: keyof typeof resources,
      _payload: undefined,
      callback: (err: unknown, res: { data: unknown; status: number }) => void,
    ) => {
      try {
        if (window.LOCAL_TRANSLATIONS)
          return callback(null, { status: 200, data: resources[url].translation })

        const raw = await fetch(`${translationsEndpoint}/${translationsAppId}/lang/${url}/`)
        const status = raw.status
        const data = await raw.json()

        return callback(null, { status, data })
      } catch (error) {
        console.error(
          `${translationsPrefix} Error getting translations from API. 
          This is likely due to poor network connection, CORS configuration or other connectivity problems rather than API itself`,
          error,
        )
        console.warn(`${translationsPrefix} Falling back to English translations from local source`)

        return callback(null, { status: 200, data: enGB })
      }
    },
  },
}

i18n.use(LanguageDetector).use(initReactI18next).use(HttpApi).init(initOptions)

export default i18n
