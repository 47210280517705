import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Suspense, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useFeatureFlag } from 'configcat-react'

import { zIndexes } from '@constants/zIndexes'
import { AboutFooter } from '@components/atoms/About/About'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { TitleHeaderH1LCSS, TitleHeaderH1SCSS } from '@components/atoms/typography/css'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { colours, gradients } from '@configs/colours'
import { mediaQueries } from '@utils/mediaQueries'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { MiscelaneousSpecialTitleBoldCSS } from '@components/atoms/typography/css/MiscelaneouSpecialTitleBoldCSS'
import { InfoWithBadge } from '@components/atoms/InfoWithBadge/InfoWithBadge'
import { TitleHeaderH2CSS } from '@components/atoms/typography/css/TitleHeaderH2CSS'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { TextInput } from '@components/molecules/forms/inputs/Input'
import { Checkbox } from '@components/molecules/forms/inputs/Checkbox'
import { ReferFriendFAQ } from '@components/molecules/refer/FAQ'
import { Survey } from '@components/organisms/Survey/Survey'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { LoadingButton } from '@components/atoms/LoadingButton/LoadingButton'
import { getValidationSchema } from '@schemas/pages/refer/enroll/schema'
import { VectorsDict } from '@components/atoms/vectors/dict'
import { useParams } from 'react-router-dom'
import { api } from '@services/api'
import { useSettingsContext } from '@hooks/useSettings'
import { HttpError } from '@errors/httpError'
import { GeneralError } from '@components/molecules/forms/GeneralError/GeneralError'
import { HttpCodeNames, HttpCodes } from '@typeDeclarations/httpCodes'
import { Banner } from '@components/atoms/tooltips/Banners/Banner'
import { MorePanels } from '@components/molecules/refer/MorePanels'
import { JoinablePanel } from '@services/api.types'

const Page: React.FC = () => {
  const { t } = useTranslation()
  const { value: referFriendEnrollFAQEnabled } = useFeatureFlag(
    'shop_refer_friend_enroll_faq_enabled',
    false,
  )

  const { referralCode } = useParams()
  const { panel, setPanel, setPersonalization } = useSettingsContext()

  const [postSignUp, setPostSignUp] = useState(false)
  const [enrollLoading, setEnrollLoading] = useState(false)
  const [enrollError, setEnrollError] = useState(false)
  const [surveyRef, setSurveyRef] = useState<string>()
  const [joinablePanels, setJoinablePanels] = useState<JoinablePanel[]>()

  const [userData, setUserData] = useState<{
    first_name?: string
    last_name?: string
    email: string
    phone_number?: string
    phone_area_code?: string
  }>()

  const [panelNotFound, setPanelNotFound] = useState(false)

  const [formNotFoundError, setFormNotFoundError] = useState(false)
  const [formAlreadySignedUpError, setFormAlreadySignedUpError] = useState(false)

  const scrollToForm = () => document.getElementById('sign-up-view')?.scrollIntoView()

  useEffect(() => {
    if (referralCode)
      api
        .referralDetail(referralCode)
        .then((response) => {
          setPersonalization(response.panel_personalization)
          setPanel(response.panel)
        })
        .catch((e) => {
          if (e instanceof HttpError) {
            if (e.status === HttpCodes.get(HttpCodeNames.NotFound)) {
              setPanelNotFound(true)
            }
          }
        })
  }, [referralCode])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const initialValues = {
    email: '',
    givenName: '',
    lastName: '',
    phoneAreaCode: '',
    phoneNumber: '',
    termsAccepted: false,
  }

  useEffect(() => {
    api.listPanels().then((r) => setJoinablePanels(r))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleSubmit = async ({
    givenName,
    lastName,
    email,
    phoneNumber,
    phoneAreaCode,
  }: typeof initialValues) => {
    const adapted = {
      first_name: givenName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      phone_area_code: phoneAreaCode,
    }

    setFormNotFoundError(false)
    setFormAlreadySignedUpError(false)

    try {
      setEnrollLoading(true)
      setEnrollError(false)

      if (!referralCode) throw new Error('[Referral] No referral code')

      const {
        panel: { pk },
      } = await api.referralSignUp(referralCode, adapted)

      setSurveyRef(pk)

      setUserData(adapted)

      setPostSignUp(true)
    } catch (e: unknown) {
      if (e instanceof HttpError && e.status === 404) setFormNotFoundError(true)
      if (e instanceof HttpError && e.status === 400) {
        if (
          e.json &&
          typeof e.json === 'object' &&
          'message' in e.json &&
          e.json.message === 'already_signed_up'
        ) {
          setFormAlreadySignedUpError(true)
        }
      }
      setEnrollError(true)
    } finally {
      setEnrollLoading(false)
    }
  }

  return (
    <Root>
      <BannerArea>
        {panelNotFound && (
          <Banner title={t('referral.errors.panelNotFound.title')} type="tipIdea">
            {t('referral.errors.panelNotFound.description')}
          </Banner>
        )}
      </BannerArea>
      <DecorativeGifts>
        <Suspense>
          <GiftOne width="120px" height="120px" fill={colours.plum[10]} />
          <GiftTwo width="220px" height="220px" fill={colours.mandarin[20]} />
          <GiftThree width="60px" height="60px" fill={colours.blues[20]} />
        </Suspense>
      </DecorativeGifts>
      {!postSignUp && (
        <>
          <IntroContent>
            <WidthBoundary>
              <Grid>
                <Info>
                  <Title>{t('youHaveReceivedCredit')}</Title>
                  <Subtitle>{t('becomePanelistEncouragement')}</Subtitle>
                </Info>
                <Buttons>
                  <PrimaryButton
                    iconRight={Icons.ArrowRight}
                    type="button"
                    onClick={scrollToForm}
                    disabled={panelNotFound}
                  >
                    {t('becomePanelist')}
                  </PrimaryButton>
                </Buttons>
              </Grid>
            </WidthBoundary>
          </IntroContent>
          <HowDoesItWorkContent>
            <ExplanationWidthWrapper>
              <DarkMiscTitle>{t('howDoesItWork')}</DarkMiscTitle>
              <RegularGray>{t('howDoesItWorkExplanation')}</RegularGray>
              <BadgeInfos>
                <InfoWithBadge icon={Icons.People}>{t('refer.enroll.steps.one')}</InfoWithBadge>
                <InfoWithBadge icon={Icons.Gift}>{t('refer.enroll.steps.two')}</InfoWithBadge>
                <InfoWithBadge icon={Icons.Shop}>{t('refer.enroll.steps.three')}</InfoWithBadge>
              </BadgeInfos>
              <StyledPrimaryButton
                iconRight={Icons.ArrowRight}
                onClick={scrollToForm}
                disabled={panelNotFound}
              >
                {t('signUp')}
              </StyledPrimaryButton>
            </ExplanationWidthWrapper>
          </HowDoesItWorkContent>
          {referFriendEnrollFAQEnabled && (
            <WidthBoundary>
              <FAQRoot>
                <ReferFriendFAQ />
              </FAQRoot>
            </WidthBoundary>
          )}
          <SignupWrapper id="sign-up-view">
            <SignupTitle>{t('signupToBecomePanelist')}</SignupTitle>
            <DarkGray>{t('rewardsAreWaiting')}</DarkGray>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validateOnMount={false}
              validationSchema={getValidationSchema(t)}
            >
              {() => (
                <WithTranslateFormErrors>
                  <Form>
                    <Row>
                      <StyledTextInput
                        autoComplete="given-name"
                        description={t('forms.fields.givenName')}
                        name="givenName"
                        type="text"
                        placeholder={t('forms.placeholders.givenName')}
                      />
                      <StyledTextInput
                        autoComplete="last-name"
                        description={t('forms.fields.lastName')}
                        name="lastName"
                        type="text"
                        placeholder={t('forms.placeholders.lastName')}
                      />
                    </Row>
                    <StyledTextInput
                      autoComplete="email"
                      description={t('forms.fields.email')}
                      name="email"
                      type="text"
                      placeholder={t('forms.placeholders.email')}
                    />
                    <PhoneRow>
                      <StyledTextInput
                        autoComplete="phoneAreaCode"
                        description={t('forms.fields.phoneAreaCode')}
                        name="phoneAreaCode"
                        type="text"
                        placeholder={t('forms.placeholders.phoneAreaCode')}
                      />
                      <StyledTextInput
                        autoComplete="phoneNumber"
                        description={t('forms.fields.phoneNumber')}
                        name="phoneNumber"
                        type="text"
                        placeholder={t('forms.placeholders.phoneNumber')}
                      />
                    </PhoneRow>
                    <StyledCheckbox
                      name="termsAccepted"
                      label={
                        <span>
                          {panel?.content.terms_and_conditions ? (
                            <Trans
                              i18nKey="iAcceptTermsLink"
                              components={[
                                <LinkStyle
                                  href={panel?.content.terms_and_conditions}
                                  key="idk"
                                  target="_blank"
                                />,
                              ]}
                            />
                          ) : (
                            t('iAcceptTerms')
                          )}
                        </span>
                      }
                    />
                    {enrollLoading ? (
                      <LoadingButton />
                    ) : (
                      <SignupPrimaryButton
                        disabled={panelNotFound}
                        iconRight={Icons.ArrowRight}
                        type="submit"
                      >
                        {enrollError ? t('somethingWentWrongTryAgain') : t('forms.actions.signUp')}
                      </SignupPrimaryButton>
                    )}
                    <GeneralError
                      $visible={formNotFoundError}
                      onClick={() => setFormNotFoundError(false)}
                    >
                      {t('referral.referralCodeDoesNotExist')}
                    </GeneralError>
                    <GeneralError
                      $visible={formAlreadySignedUpError}
                      onClick={() => setFormAlreadySignedUpError(false)}
                    >
                      {t('referral.alreadySignedUp')}
                    </GeneralError>
                  </Form>
                </WithTranslateFormErrors>
              )}
            </Formik>
          </SignupWrapper>
        </>
      )}
      {postSignUp && (
        <>
          <IntroContent>
            <WidthBoundary>
              <Grid>
                <Info>
                  <Title>{t('youHaveSignedUp')}</Title>
                  <Subtitle>{t('weHaveReceivedYourSignUp')}</Subtitle>
                </Info>
              </Grid>
            </WidthBoundary>
          </IntroContent>
          {userData && (
            <MorePanels
              title={t('wannaEarnEvenMore')}
              subtitle={t('whyNotJoinOtherPanels')}
              joinablePanels={joinablePanels}
              userData={userData}
            />
          )}
          <SurveyFrame>
            <SurveyTitle>{t('careToRateExperience')}</SurveyTitle>
            <SurveyDescription>{t('careToRateExperienceDescription')}</SurveyDescription>
            {surveyRef && <Survey object_ref={surveyRef} type="panel" />}
          </SurveyFrame>
        </>
      )}
      <AboutFooter $noBackground />
    </Root>
  )
}

const BannerArea = styled.div`
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  ${mediaQueries.from.breakpoint.desktop} {
    min-height: 96px;
  }
`

const LinkStyle = styled.a`
  color: ${colours.brand.blue};
  text-decoration: underline;
`

const DecorativeGifts = styled.div`
  position: relative;
  width: 100%;
  pointer-events: none;
`

const GiftOne = styled(VectorsDict.gift)`
  position: absolute;
  top: 48px;
  left: 88px;
  opacity: 0.5;
  transform: rotate(-25deg);
`

const GiftTwo = styled(VectorsDict.gift)`
  position: absolute;
  top: 228px;
  left: 288px;
  transform: rotate(25deg);
  opacity: 0.6;
`

const GiftThree = styled(VectorsDict.gift)`
  position: absolute;
  top: 360px;
  right: 288px;
  transform: rotate(25deg);
  opacity: 0.4;
`

const SurveyTitle = styled.div`
  ${MiscelaneousSpecialTitleBoldCSS};
  color: ${colours.mist[900]};
  margin-bottom: 8px;
  text-align: center;
`

const SurveyDescription = styled.div`
  ${BodyLargeCSS};
  color: ${colours.brand.dark};
  margin-bottom: 8px;
  text-align: center;
`

const SurveyFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background: ${colours.prisma.white};
  max-width: 296px;
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  position: relative;
  z-index: ${zIndexes.baseOnTop};
`

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 40px;
`

const Row = styled.div`
  display: flex;
  gap: 8px;
  ${mediaQueries.to.breakpoint.desktop} {
    flex-direction: column;
  }
`

const PhoneRow = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: min-content 1fr;
  ${mediaQueries.to.breakpoint.desktop} {
    grid-template-columns: 1fr;
  }
`

const StyledTextInput = styled(TextInput)`
  margin-bottom: 24px;
  position: relative;
  &:last-of-type {
    margin-bottom: 24px;
  }
  & input:not(:focus) ~ aside {
    display: none;
  }
`

const SignupTitle = styled.div`
  ${TitleHeaderH2CSS};
  margin-bottom: 8px;
`

const SignupWrapper = styled.div`
  width: 100%;
  background-color: ${colours.prisma.white};
  ${mediaQueries.to.breakpoint.desktop} {
    padding: 32px 24px 40px 24px;
    box-sizing: border-box;
    scroll-margin-top: calc(72px + 8px);
  }
  ${mediaQueries.from.breakpoint.desktop} {
    width: 600px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 24px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    padding: 32px;
    scroll-margin-top: calc(72px + 8px + 8px);
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 64px;
  max-width: 400px;
`

const SignupPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 24px;
`

const FAQRoot = styled.div`
  margin-bottom: 96px;
  ${mediaQueries.from.breakpoint.desktop} {
    width: 600px;
  }
`

const BadgeInfos = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: flex-start;
  margin-bottom: 32px;
  ${mediaQueries.to.breakpoint.tablet} {
    flex-direction: column;
    align-items: center;
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  max-width: 400px;
`

const DarkMiscTitle = styled.div`
  ${MiscelaneousSpecialTitleBoldCSS};
  color: ${colours.brand.dark};
  margin-bottom: 8px;
`

const Title = styled.div`
  margin-bottom: 16px;
  ${mediaQueries.from.breakpoint.desktop} {
    ${TitleHeaderH1LCSS}
  }
  ${mediaQueries.to.breakpoint.desktop} {
    ${TitleHeaderH1SCSS}
  }
`

const Subtitle = styled(Fonts.IntroSubtextLarge)`
  color: ${colours.mist[800]};
  margin-bottom: 16px;
`

const RegularGray = styled(Fonts.BodyLarge)`
  color: ${colours.mist[700]};
  margin-bottom: 24px;
`

const DarkGray = styled(Fonts.BodyLarge)`
  color: ${colours.mist[800]};
  margin-bottom: 24px;
`

const ExplanationWidthWrapper = styled(WidthBoundary)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mediaQueries.from.breakpoint.desktop} {
    width: 832px;
    padding: 48px;
  }
`

const Info = styled.div`
  text-align: center;
`

const Grid = styled.div`
  display: grid;
  gap: 40px;
  margin-bottom: 48px;
  justify-items: center;
  align-items: center;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    margin-bottom: 64px;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 10;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  ${gradients.defaultBackground};
`

const HowDoesItWorkContent = styled.div`
  background-color: ${colours.prisma.white};
  width: 100%;
  display: flex;
  margin-bottom: 96px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const IntroContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
  z-index: ${zIndexes.baseOnTop};
`

export default Page
