export const PATHS = {
  account: 'account',
  buttons: 'buttons',
  card: 'card',
  cart: 'cart',
  catalogue: 'catalogue',
  checkout: 'checkout',
  preparingChoiceCard: 'preparing-choice-card',
  claim: 'claim',
  claimSkip: 'details',
  claimCodeSelect: 'claim-code-select',
  code: 'code',
  combine: 'combine',
  confetti: 'confetti',
  enterCode: 'enter-code',
  gateway: 'gateway',
  order: 'order',
  overview: 'overview',
  paymentless: 'paymentless',
  previews: 'previews',
  processCode: 'process-code',
  receive: 'receive',
  receiveFlowSelect: 'receive-flow-select',
  root: '/',
  signUp: 'sign-up',
  typography: 'typography',
  welcome: 'welcome',
  getShareKey: 'get-share-key',
  refer: 'refer',
  panels: 'panels',
  eon: 'eon',
  activate: 'activate',
  enroll: 'enroll',
}

export const SUBPATH_PARAMS = {
  step: ':step',
  substep: ':substep',
  orderId: ':orderId',
}

export const SUBPATHS = {
  checkoutReview: 'review',
  checkoutPaymentMethod: 'payment-method',
}

export const PATH_PARAMS = {
  claimCode: ':claimCode',
  id: ':id',
  orderId: ':orderId',
  productId: ':productId',
  shareKey: ':shareKey',
  referralCode: ':referralCode',
}
