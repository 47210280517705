import { getFetchConfig } from '@configs/api'
import { apiPaths } from '@configs/urls'
import { AvailableSurveys, getSurveyConfig } from '@constants/survey'

import {
  ActivateInfo,
  AfterJoinPanelResponse,
  Catalogue,
  CheckCardFlow,
  ClaimInfo,
  JoinablePanel,
  ParticipantData,
  ReceiveInfo,
  ReferralDetail,
  ReferralSignUpData,
  ShopOrder,
  ShopOrderBody,
} from '@services/api.types'
import { CalculateFeeParameters, CalculateFeeResponse } from '@typeDeclarations/api/calculateFee'
import { CalculateFeesParameters, CalculateFeesResponse } from '@typeDeclarations/api/calculateFees'

import { ShareKeyByCodesResponse } from '@typeDeclarations/api/shareKeyByCodesResponse'

import { fetchApiDataOrThrow } from './fetchApiDataOrThrow'
import { DataWithStatus, fetchApiDataWithStatusOrThrow } from './fetchApiDataWithStatusOrThrow'
import { fetchApiOrThrow } from './fetchApiOrThrow'

type ClaimArgs = {
  email: string | null
  shareKey: string
  cognito_id: string | null
  unconfirmed_user: boolean
}

export const api = {
  receiveInfo: async (shareKey: string, languageCode?: string): Promise<ReceiveInfo> => {
    const config = await getFetchConfig({ noAuth: true, languageCode })
    return await fetchApiDataOrThrow<ReceiveInfo>(apiPaths.shareKeyInfo(shareKey), config)
  },
  receiveClaim: async (claimArgs: ClaimArgs): Promise<ClaimInfo> => {
    const body = claimArgs
    const config = await getFetchConfig({
      noAuth: true,
      method: 'POST',
      body: JSON.stringify(body),
    })

    return await fetchApiDataOrThrow<ClaimInfo>(apiPaths.shareKeyClaim(claimArgs.shareKey), config)
  },
  claimCodeClaim: async (
    email: string,
    claimCode: string,
    cognito_id?: string,
  ): Promise<ClaimInfo> => {
    const body = { email, cognito_id }
    const config = await getFetchConfig({
      noAuth: true,
      method: 'POST',
      body: JSON.stringify(body),
    })

    return await fetchApiDataOrThrow<ClaimInfo>(apiPaths.claimCodeClaim(claimCode), config)
  },
  checkCardFlow: async (code: string): Promise<CheckCardFlow> => {
    const config = await getFetchConfig({ noAuth: true })

    return await fetchApiDataOrThrow<CheckCardFlow>(apiPaths.claimCodeCheckCardFlow(code), config)
  },
  cardDetail: async (code: string): Promise<ReceiveInfo> => {
    const config = await getFetchConfig()

    return await fetchApiDataOrThrow(apiPaths.claimCodeCardDetail(code), config)
  },
  checkCardCompatibility: async (into: string, codeToAdd: string): Promise<ReceiveInfo> => {
    const config = await getFetchConfig({
      method: 'POST',
      body: JSON.stringify({ claim_code: codeToAdd }),
    })

    return await fetchApiDataOrThrow(apiPaths.claimCodeCardAdd(into), config)
  },
  shopOrder: async (body: ShopOrderBody): Promise<ShopOrder> => {
    const config = await getFetchConfig({
      method: 'POST',
      body: JSON.stringify(body),
    })

    return await fetchApiDataOrThrow(apiPaths.shopOrder(), config)
  },
  shopOrderDetail: async (orderId: string, language?: string): Promise<ShopOrder> => {
    const config = await getFetchConfig({ languageCode: language })

    return await fetchApiDataOrThrow(apiPaths.orderIdShopOrderDetail(orderId), config)
  },
  catalogue: async (claim_code: string, language?: string): Promise<Catalogue> => {
    const config = await getFetchConfig({ languageCode: language })

    return await fetchApiDataOrThrow(apiPaths.claimCodeShopCatalogue(claim_code), config)
  },
  cardUpdateBalance: async (claim_code: string): Promise<Catalogue> => {
    const config = await getFetchConfig()

    return await fetchApiDataOrThrow(apiPaths.claimCodeCardUpdateBalance(claim_code), config)
  },
  shopSurvey: async (
    object_ref: string,
    rating: number,
    { type }: { type: AvailableSurveys },
  ): Promise<void> => {
    const { survey_id, object_type } = getSurveyConfig(type)
    const body = {
      object_ref,
      object_type,
      rating,
      survey_id,
    }

    const config = await getFetchConfig({
      method: 'POST',
      body: JSON.stringify(body),
    })

    await fetchApiOrThrow(apiPaths.shopSurvey(), config)
  },
  shareKeyByCodes: async (
    card_number: string,
    security_code: string,
  ): Promise<ShareKeyByCodesResponse> => {
    const body = { card_number, security_code }
    const config = await getFetchConfig({ method: 'POST', body: JSON.stringify(body) })

    return await fetchApiDataOrThrow(apiPaths.shareKeyByCodes(), config)
  },
  activate: async (cardNumber: string): Promise<DataWithStatus<ActivateInfo>> => {
    const config = await getFetchConfig()

    return await fetchApiDataWithStatusOrThrow(apiPaths.activate(cardNumber), config)
  },
  calculateFee: async ({
    amount,
    claim_code,
    pk,
    value,
  }: CalculateFeeParameters): Promise<CalculateFeeResponse> => {
    const config = await getFetchConfig({
      method: 'POST',
      body: JSON.stringify({ value, claim_code, amount }),
    })

    return await fetchApiDataOrThrow(apiPaths.calculateFee(pk), config)
  },
  calculateFees: async (parameters: CalculateFeesParameters): Promise<CalculateFeesResponse> => {
    const config = await getFetchConfig({
      method: 'POST',
      body: JSON.stringify(parameters),
    })

    return await fetchApiDataOrThrow(apiPaths.calculateFees(), config)
  },
  referralDetail: async (referralCode: string | number): Promise<ReferralDetail> => {
    const config = await getFetchConfig()

    return await fetchApiDataOrThrow(apiPaths.referralDetail(referralCode), config)
  },
  referralSignUp: async (
    referralCode: string | number,
    referralSignUpData: ReferralSignUpData,
  ): Promise<ReferralDetail> => {
    const config = await getFetchConfig({
      method: 'POST',
      body: JSON.stringify(referralSignUpData),
    })

    return await fetchApiDataOrThrow(apiPaths.referralSignUp(referralCode), config)
  },
  createReferralCode: async (claimCode: string, email: string): Promise<ReferralDetail> => {
    const config = await getFetchConfig({
      method: 'POST',
      body: JSON.stringify({ email }),
    })

    return await fetchApiDataOrThrow(apiPaths.createReferralCode(claimCode), config)
  },
  listPanels: async (): Promise<JoinablePanel[]> => {
    const config = await getFetchConfig()

    return await fetchApiDataOrThrow(apiPaths.listPanels(), config)
  },
  panelSignUp: async (participantData: ParticipantData[]): Promise<AfterJoinPanelResponse> => {
    const body = JSON.stringify(participantData)
    const config = await getFetchConfig({ method: 'POST', body })

    return await fetchApiDataOrThrow(apiPaths.panelSignUp(), config)
  },
}
