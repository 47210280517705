import { ArrayHelpers, FieldArray, useFormikContext } from 'formik'
import { Suspense, useEffect, useRef, useState } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { AboutFooter } from '@components/atoms/About/About'
import { ChoiceCardBar } from '@components/atoms/ChoiceCardBar/ChoiceCardBar'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { ReceiptSection } from '@components/molecules/checkout/ReceiptSection'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { ShoppingCartCard } from '@components/molecules/forms/ShoppingCartCard/ShoppingCartCard'
import { MobileSummary } from '@components/molecules/Summary/MobileSummary'
import { BottomNav } from '@components/organisms/BottomNav/BottomNav'
import { AddChoiceCards } from '@components/organisms/modals/AddChoiceCards/AddChoiceCards'
import { colours } from '@configs/colours'
import { cartSteps } from '@configs/urls'
import { datadogMessages } from '@constants/datadog'
import { CartItem } from '@context/cart'
import { datadogLogs } from '@datadog/browser-logs'
import { useCartContext } from '@hooks/useCart'
import { useCheckoutContext } from '@hooks/useCheckout'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useMainKeyContext } from '@hooks/useMainKey'
import { useProductsContext } from '@hooks/useProducts'

import { useSettingsContext } from '@hooks/useSettings'
import { CheckoutFormikValues } from '@typeDeclarations/checkoutFormikValues'
import { Icons } from '@typeDeclarations/components/atoms/icons'

import { calculateCardValueWithFee } from '@utils/calculateCardValueWithFee'
import { deepCopy } from '@utils/deepCopy'
import { getMainChoiceCardCurrency } from '@utils/getMainChoiceCardCurrency'
import { breakpoints, mediaQueries } from '@utils/mediaQueries'
import { once } from '@utils/once'

type Props = {
  currency: string | undefined
}

const MAX_DROPDOWN_OPTIONS = 25
const THRESHOLD = 500

export const Cart: React.FC<Props> = () => {
  const { values } = useFormikContext<CheckoutFormikValues>()
  const { products: data } = useProductsContext()
  const { aggregatedCart, isMainChoiceCardEmpty, saveCart } = useCartContext()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { mainKey } = useMainKeyContext()
  const { hashFlows } = useHashFlowContext()
  const { reloadShopConfig } = useSettingsContext()
  const { reloadProducts } = useProductsContext()
  const [addingChoiceCardOpen, setAddingChoiceCardOpen] = useState(false)
  const [last, setLast] = useState<number>()
  const { choiceCardsCount, totalCardsCount, calculatedFees, getFeesFromBackend } =
    useCheckoutContext()

  const [isProcessing, setIsProcessing] = useState(false)

  const initialBackendFeesCalculation = useRef(once(getFeesFromBackend)).current

  const handleNavigate = async () => {
    try {
      setIsProcessing(true)

      await reloadProducts()
      await reloadShopConfig()
      saveCart()
    } catch {
      console.warn('[Navigation] Cannot reload data')
    } finally {
      datadogLogs.logger.info(datadogMessages.checkoutEntered)
      navigate(generatePath(cartSteps.checkoutReview, { claimCode: mainKey }))

      setIsProcessing(false)
    }
  }

  useEffect(() => {
    if (window.innerWidth < breakpoints.desktop)
      window.scrollTo({
        behavior: 'instant',
        left: 0,
        top: 0,
      })
  }, [])

  const getDropdownValues = (values: CheckoutFormikValues, idx: number) => {
    const { floor, max, min } = Math

    const totalByType = (a: number, c: CartItem) => (c.id === currentId ? c.quantity + a : a)
    const cardAmount = values.cards[idx].amount
    let maxPurchaseQuantityTotal = mainKey ? hashFlows[mainKey]?.maxPurchaseQuantity : null
    let maxPurchaseQuantityByType = values.cards[idx].maximumQuantity
    let maxPurchaseAmount = mainKey ? hashFlows[mainKey]?.maxPurchaseAmount : null
    if (typeof maxPurchaseQuantityTotal !== 'number') maxPurchaseQuantityTotal = Infinity
    if (typeof maxPurchaseQuantityByType !== 'number') maxPurchaseQuantityByType = Infinity
    if (typeof maxPurchaseAmount !== 'number') maxPurchaseAmount = Infinity

    const totalCardsInCart = values.cards.reduce((a, c) => c.quantity + a, 0)
    const totalAmountInCart = values.cards.reduce((a, c) => c.quantity * c.amount + a, 0)
    const currentEntryCardsCount = values.cards[idx].quantity
    const currentId = values.cards[idx].id
    const totalCardsInCartByType = values.cards.reduce(totalByType, 0)
    const freeSpotsByTotal = max(0, maxPurchaseQuantityTotal - totalCardsInCart)
    const freeSpotsByType = max(0, maxPurchaseQuantityByType - totalCardsInCartByType)
    const freeSpotsByAmount = max(0, floor((maxPurchaseAmount - totalAmountInCart) / cardAmount))
    const freeSpots = min(freeSpotsByTotal, freeSpotsByType, freeSpotsByAmount)
    const optionsLength = min(currentEntryCardsCount + freeSpots, MAX_DROPDOWN_OPTIONS)

    return [...Array(optionsLength).keys()]
  }

  initialBackendFeesCalculation(values)?.catch((e: unknown) =>
    console.error('[Calculate fee] Error calculating initial fee in cart', e),
  )

  return (
    <Root>
      <WidthBoundary>
        {addingChoiceCardOpen && (
          <Suspense>
            <AddChoiceCards
              onClose={() => {
                setAddingChoiceCardOpen(false)
              }}
              open={addingChoiceCardOpen}
            />
          </Suspense>
        )}
        <TopArrangement>
          <div>
            <Title>{t('cart')}</Title>
            <CartInfo>{t('basketTip')}</CartInfo>
          </div>
          <ChoiceCardBar
            action={() => setAddingChoiceCardOpen(true)}
            mainInfo={`${t('choiceCards')} (${choiceCardsCount})`}
          />
        </TopArrangement>
      </WidthBoundary>
      {!values.cards.length && (
        <EmptyCart>
          <EmptyCartCircle>
            <Suspense>
              <IconComponents.cart height="55" width="55" />
            </Suspense>
          </EmptyCartCircle>
          <Fonts.ParagraphTitle>{t('cartEmpty')}</Fonts.ParagraphTitle>
          <EmptyCartDescription>{t('cartEmptyDescription')}</EmptyCartDescription>
        </EmptyCart>
      )}
      {values.cards.length > 0 && (
        <ChoiceCardsWrapper>
          <Items>
            <Suspense>
              <IconComponents.cart />
            </Suspense>
            {t('items', { count: totalCardsCount })}
          </Items>
          <CardsAndSummary>
            <Cards>
              <FieldArray
                name="cards"
                render={(arrayHelpers: ArrayHelpers) => {
                  return values.cards.map(({ id, ...rest }, idx) => {
                    const equivalent = data?.products.find(
                      (product) => String(product.id) === String(id),
                    )
                    const calculatedBalanceEquivalent = calculatedFees?.products_with_fees.find(
                      (el) =>
                        Number(el.product_pk) === Number(id) && el.fee + el.value === rest.amount,
                    )?.value

                    const description = equivalent?.description_short ?? rest.description
                    const name = equivalent?.name ?? rest.name
                    const feeInformaiton = calculateCardValueWithFee(
                      rest.amount,
                      rest.feePaymentOption,
                      rest.feeFlat,
                      rest.feePercentage,
                      rest.denominatedValues,
                    )

                    return (
                      <ShoppingCartCard
                        calculatedBalance={calculatedBalanceEquivalent}
                        cardId={id}
                        currency={rest.currency}
                        description={description}
                        feeInformation={feeInformaiton}
                        imageUrl={rest.imageUrl}
                        key={id + idx.toString()}
                        name={name}
                        quantityField={{
                          name: `cards[${idx}].quantity`,
                          idx,
                          values: getDropdownValues(values, idx)
                            .map((c) => c + 1)
                            .map((c) => ({ id: c, text: c, value: c })),
                        }}
                        removeHandler={() => {
                          const now = new Date().getTime()

                          if (!last || now - last > THRESHOLD) {
                            setLast(now)
                            arrayHelpers.remove(idx)
                            const copy = deepCopy(values)
                            copy.cards.splice(idx, 1)
                            void getFeesFromBackend(copy)
                          }
                        }}
                      />
                    )
                  })
                }}
              />
            </Cards>
            <Vr />
            <Desktop>
              <ExtendedReceipt>
                <ReceiptSection currency={values.cards[0]?.currency} />
                <PrimaryButton
                  disabled={values.cards.length === 0 || isMainChoiceCardEmpty() || isProcessing}
                  iconRight={isMainChoiceCardEmpty() ? Icons.Warning : Icons.ArrowRight}
                  onClick={handleNavigate}
                  type="button"
                >
                  {isMainChoiceCardEmpty()
                    ? t('mainChoiceCardEmpty')
                    : t('forms.actions.continueToCheckout')}
                </PrimaryButton>
              </ExtendedReceipt>
            </Desktop>
          </CardsAndSummary>
        </ChoiceCardsWrapper>
      )}
      <AboutFooter />
      {values.cards.length > 0 && (
        <MobileSummary currency={getMainChoiceCardCurrency(aggregatedCart, mainKey)}>
          <PrimaryButton
            disabled={values.cards.length === 0 || isMainChoiceCardEmpty() || isProcessing}
            iconRight={Icons.ArrowRight}
            onClick={handleNavigate}
            type="button"
          >
            {isMainChoiceCardEmpty()
              ? t('mainChoiceCardEmpty')
              : t('forms.actions.continueToCheckout')}
          </PrimaryButton>
        </MobileSummary>
      )}
      <BottomNav />
    </Root>
  )
}

const Root = styled.div``

const Desktop = styled.div`
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

const CardsAndSummary = styled.div`
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: 2fr min-content 1fr;
  }
`

const ExtendedReceipt = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const Vr = styled.div`
  height: 100%;
  border-right: 1px solid ${colours.mist[300]};
`
const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ChoiceCardsWrapper = styled(WidthBoundary)`
  flex-grow: 10;
  background-color: ${colours.mist[200]};
  padding-top: 24px;
  padding-bottom: 40px;
`

const TopArrangement = styled.div`
  display: grid;
  margin-bottom: 32px;
  margin-top: 16px;
  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: 2fr 1fr;
    align-items: end;
    margin-top: 80px;
    margin-bottom: 48px;
  }
`

const EmptyCartCircle = styled.div`
  width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${colours.prisma.white};
`

const EmptyCart = styled.div`
  align-items: center;
  background-color: ${colours.mist[200]};
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 64px 0px 80px 0px;
  text-align: center;
  width: 100%;
`

const EmptyCartDescription = styled(Fonts.BodyRegular)`
  color: ${colours.mist[700]};
  padding: 0 16px;
`

const Items = styled(Fonts.ButtonCTALabelLarge)`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  ${mediaQueries.to.breakpoint.desktop} {
    margin-bottom: 12px;
  }
`

const CartInfo = styled(Fonts.BodyRegular)`
  color: ${colours.mist[800]};
  margin: 8px 0;
`

const Title = styled(Fonts.TitleHeaderH1S)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
